import React from 'react'
import styled from 'styled-components'
import {MainLayout} from 'layouts'
import {AnchorButton, LinkButton, SEO} from 'components'
import BackgroundSVG from 'images/404-background.svg'

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  background: center / cover no-repeat url(${BackgroundSVG});
  background-color: var(--dark-blue);

  color: var(--white);
  text-align: center;

  a {
    color: var(--yellow);
  }

  h1 {
    margin: 0;
  }

  h2 {
    max-width: 400px;
  }
`

export const NotFoundPage = () => (
  <MainLayout theme='white'>
    <SEO title='404: Not found' />
    <Content>
      <h1>Oops!</h1>
      <h2>The page you{`'`}re looking for got lost in space...</h2>
      <p>
        In the meantime, start building some{' '}
        <LinkButton tier='tertiary' to='/projects'>
          projects
        </LinkButton>
        ,<br /> learn about some{' '}
        <LinkButton tier='tertiary' to='/topics'>
          topics
        </LinkButton>
        ,<br /> or get career tips from our{' '}
        <AnchorButton tier='tertiary' href='https://blog.thecodex.me'>
          blog
        </AnchorButton>
        !
      </p>
    </Content>
  </MainLayout>
)

export default NotFoundPage
